import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import "@/utils/rem";
import 'normalize.css';
import Vant from 'vant';

import 'vant/lib/index.css';
import '@/router/permission';


Vue.prototype.$dict = (key, index) => {
  if (index === void 0) return dict[key]
  return dict[key][index]
}

// const app = createApp(App)
Vue.use(Vant);
Vue.config.productionTip = false

export const root = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
