import Vue from 'vue'
import Vuex from 'vuex'
import cookie from "vue-cookies"
Vue.use(Vuex)

// import { getToken, setToken, removeToken } from '@/utils/auth'

export default new Vuex.Store({
  state: {
    pass: localStorage.getItem('wyswps') ? JSON.parse(localStorage.getItem('wyswps')) : ""
  },
  mutations: {
    setPass(state, payload) {
      state.pass = payload.pass;
      localStorage.setItem('wyswps', JSON.stringify(payload.pass))
    },

  },
  actions:{
    setPass(context, payload){
      context.commit('setPass', payload)
    },
  },
  modules: {

  }
})
