import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [{
  path: '/',
  redirect: '/home'
},
  {
    path: '/wymap',
    name: 'Wymap',
    component: () => import('@/views/Wymap.vue')
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/live',
    name: 'Live',
    component: () => import('@/views/Live.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/About.vue')
  },
  {
    path: '/propose',
    name: 'propose',
    component: () => import('@/views/propose.vue')
  },
  {
    path: '/manage',
    name: 'manage',
    component: () => import('@/views/manage.vue')
  },
  {
    path: '/business',
    name: 'business',
    component: () => import('@/views/business.vue')
  },
  {
    path: '/history',
    name: 'history',
    component: () => import('@/views/history.vue')
  },
  {
    path: '/details',
    name: 'details',
    component: () => import('@/views/details.vue')
  },
{
  path: '/company',
  name: 'company',
  component: () => import('@/views/company.vue')
},
{
  path: '/companyDetails',
  name: 'companyDetails',
  component: () => import('@/views/companyDetails.vue')
},
{
  path: '/privateMap',
  name: 'privateMap',
  component: () => import('@/views/privateMap.vue')
},
{
  path: '/project',
  name: 'project',
  component: () => import('@/views/project.vue')
},
{
  path: '/projectDetails',
  name: 'projectDetails',
  component: () => import('@/views/projectDetails.vue')
},
]
const router = new VueRouter({
  routes,
  mode: 'hash'
})
router.afterEach((to,from,next) => {
  window.scrollTo(0,0)
})
export default router